import React from "react"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Link } from "gatsby"

import ImgBlog1 from "../../images/blog/pivonuv-beh-2021.jpg"
import ImgBlog2 from "../../images/blog/sportovni-odpoledne-na-czu.jpg"
import ImgBlog3 from "../../images/blog/oceneni-v-ramci-european-customer-centricity-awards.jpg"
import ImgBlog4 from "../../images/blog/novy-plugin-neon-nette-support.jpg"
import ImgBlog5 from "../../images/blog/moderni-technologie-pro-retail-audit.jpg"
import ImgBlog6 from "../../images/blog/podcast-o-aplikaci-springaudit.jpg"
import ImgBlog7 from "../../images/blog/sportovni-den-2022.jpg"
import ImgBlog8 from "../../images/blog/slovnicek-project-management.jpg"
import ImgBlog9 from "../../images/blog/pivonuv-beh-2023.jpg"
import ImgBlog10 from "../../images/blog/jak-vybrat-agenturu.jpg"
import ImgBlog11 from "../../images/blog/modernizace-php-aplikaci-1.jpg"
import ImgBlog12 from "../../images/blog/modernizace-php-aplikaci-2.jpg"
import ImgBlog13 from "../../images/blog/modernizace-php-aplikaci-3.jpg"

const Bloglist = (props) => (

	<Row className="mt-5">

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-3-posouzeni-php-projektu">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog13} alt="Modernizace PHP aplikací 3 - posouzení PHP projektu" />
				</div>
				<div className="ftrs-blog__date">16.09.2023</div>
				<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;3 - posouzení php&nbsp;projektu</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-2-duvody-k-aktualizaci">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog12} alt="Modernizace PHP aplikací 2 - důvody k aktualizaci" />
				</div>
				<div className="ftrs-blog__date">30.08.2023</div>
				<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;2 - důvody k&nbsp;aktualizaci</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-1-historie-php">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog11} alt="Modernizace PHP aplikací 1 - historie PHP" />
				</div>
				<div className="ftrs-blog__date">21.07.2023</div>
				<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;1 - historie&nbsp;PHP</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/jak-vybrat-agenturu-na-vyvoj-aplikaci">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog10} alt="Jak vybrat agenturu na vývoj webových aplikací" />
				</div>
				<div className="ftrs-blog__date">15.6.2023</div>
				<h2 className="ftrs-blog__title ftrs-h3">Jak vybrat agenturu na&nbsp;vývoj webových aplikací</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/pivonuv-beh-2023">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog9} alt="Pivoňův běh 2023 - potřetí po stopách legendárního Pivoně" />
				</div>
				<div className="ftrs-blog__date">29.5.2023</div>
				<h2 className="ftrs-blog__title ftrs-h3">Pivoňův běh 2023 - potřetí po&nbsp;stopách legendárního Pivoně</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/technicky-slovnicek-project-management">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog8} alt="Technický slovníček: Project management" />
				</div>
				<div className="ftrs-blog__date">15.04.2023</div>
				<h2 className="ftrs-blog__title ftrs-h3">Technický slovníček: Project management</h2>
			</Link>
		</Col>

		{
			props.place !== 'index' ? (
				<>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/sportovni-den-2022">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog7} alt="Sportovní den 2022" />
				</div>
				<div className="ftrs-blog__date">13.09.2022</div>
				<h2 className="ftrs-blog__title ftrs-h3">Sportovní den 2022</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/podcast-o-aplikaci-springaudit">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog6} alt="Podcast o aplikaci Springaudit" />
				</div>
				<div className="ftrs-blog__date">28.07.2022</div>
				<h2 className="ftrs-blog__title ftrs-h3">Podcast o aplikaci Springaudit</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/novy-plugin-neon-nette-support">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog4} alt="Nový plugin NEON Nette Support" />
				</div>
				<div className="ftrs-blog__date">09.02.2022</div>
				<h2 className="ftrs-blog__title ftrs-h3">Nový plugin NEON Nette Support</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/moderni-technologie-pro-retail-audit">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog5} alt="Modrení technologie pro retail audit" />
				</div>
				<div className="ftrs-blog__date">20.01.2022</div>
				<h2 className="ftrs-blog__title ftrs-h3">Modrení technologie pro retail audit</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/oceneni-v-ramci-european-customer-centricity-awards">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog3} alt="Ocenění v rámci European Customer Centricity Awards" />
				</div>
				<div className="ftrs-blog__date">15.10.2021</div>
				<h2 className="ftrs-blog__title ftrs-h3">Ocenění v rámci European Customer Centricity Awards</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
			<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/sportovni-odpoledne-na-czu">
				<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog2} alt="Sportovní odpoledne na ČZU" />
				</div>
				<div className="ftrs-blog__date">8.10.2021</div>
				<h2 className="ftrs-blog__title ftrs-h3">Sportovní odpoledne na&nbsp;ČZU</h2>
			</Link>
		</Col>

		<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
		<Link className="ftrs-blog__item" activeClassName="ftrs-blog__item--active" to="/cs/blog/pivonuv-beh-2021">
			<div className="ftrs-blog__img-cover">
					<img className="ftrs-blog__img" src={ImgBlog1} alt="Pivoňův běh 2021 - Proběhli jsme se v Píseckých horách" />
				</div>
				<div className="ftrs-blog__date">22.9.2021</div>
				<h2 className="ftrs-blog__title ftrs-h3">Pivoňův běh 2021 - Proběhli jsme se v&nbsp;Píseckých horách</h2>
			</Link>
		</Col>

				</>
			) : null
		}

	</Row>

)

export default Bloglist