import { Link } from "gatsby"
import React from "react"

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import "../header/header.scss"

import ImgFtrsLogoWhite from "../../images/ftrs-logo-white.svg"

function ftrsHeaderMobileBg() {
    var ftrsHeaderMobileElement = document.getElementById("ftrsHeaderMobile");
    if(ftrsHeaderMobileElement.classList.contains("navbar--mobile")) {
        ftrsHeaderMobileElement.classList.remove("navbar--mobile");
    }
    else {
        ftrsHeaderMobileElement.classList.add("navbar--mobile");
    }
}

const Headercs = () => (

    <header className="ftrs-header">

        <Navbar className="ftrs-header__navbar" id="ftrsHeaderMobile" expand="lg">
            <Link to="/cs" className="ftrs-header__logo">
                <img
                    src={ImgFtrsLogoWhite}
                    height="40"
                    width="88"
                    className="ftrs-header__logo-image"
                    alt="Future Rockstars"
                />
            </Link>
            <Navbar.Toggle onClick={ftrsHeaderMobileBg} />
            <Navbar.Collapse>
                <Nav>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/cs">Úvod</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/cs/blog" partiallyActive={true}>Blog</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/cs/sluzby" partiallyActive={true}>Služby</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/cs/reference" partiallyActive={true}>Reference</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/cs/o-nas" partiallyActive={true}>O nás</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/cs/kariera" partiallyActive={true}>Kariéra</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/cs/kontakt" partiallyActive={true}>Kontakt</Link>
                    <Link className="ftrs-header__link ftrs-header__link--en" to="/">EN</Link>
                    <Link className="ftrs-header__link ftrs-header__link--cs ftrs-header__link--active-lang" to="/cs">CZ</Link>
                    <Link className="ftrs-header__link ftrs-header__link--de" to="/de">DE</Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    </header>

)

export default Headercs