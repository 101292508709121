import React from "react"

import Layoutcs from "../../../components/layoutcs/layoutcs"
import SEO from "../../../components/seo"

import Container from 'react-bootstrap/Container'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Bloglist from "../../../components/bloglist/bloglist"

import "../../index.scss"
import "../../blog.scss"

import ImgBlogPost from "../../../images/blogpost/sportovni-odpoledne-na-czu.jpg"

import ImgBlogPostGallery1 from "../../../images/blogpost/sportovni-odpoledne-na-czu-01.jpg"
import ImgBlogPostGallery2 from "../../../images/blogpost/sportovni-odpoledne-na-czu-02.jpg"
import ImgBlogPostGallery3 from "../../../images/blogpost/sportovni-odpoledne-na-czu-03.jpg"
import ImgBlogPostGallery4 from "../../../images/blogpost/sportovni-odpoledne-na-czu-04.jpg"
import ImgBlogPostGallery5 from "../../../images/blogpost/sportovni-odpoledne-na-czu-05.jpg"
import ImgBlogPostGallery6 from "../../../images/blogpost/sportovni-odpoledne-na-czu-06.jpg"

const Blog = () => (

	<Layoutcs>
		<SEO title="Sportovní odpoledne na ČZU" description="Abychom trochu vykompenzovali spoustu času v kancelářských křeslech, zpříjemnili jsme si čekání na podzim sportovním odpolednem." />

		<Container className="ftrs-content pb-4 ftrs-blogpost">

			<h1 className="ftrs-h1">Sportovní odpoledne na&nbsp;ČZU</h1>
			<div className="ftrs-blogpost__date">8.10.2021</div>

			<img className="ftrs-blogpost__img" src={ImgBlogPost} alt="Sportovní odpoledne na&nbsp;ČZU" />

		</Container>

		<div className="ftrs-blogpost__text">
			<Container>
				
				<p>Abychom trochu vykompenzovali spoustu času v&nbsp;kancelářských křeslech, zpříjemnili jsme si čekání na&nbsp;podzim sportovním odpolednem. Pronajali jsme si část sportovního areálu ČZU na&nbsp;Suchdole a&nbsp;zahráli si fotbal, badminton a&nbsp;pinec. V&nbsp;plánu byl i&nbsp;beach volleyball, který jsme museli vzhledem k&nbsp;povětrnostním podmínkám vyměnit za&nbsp;florbal.</p>

				<p>Užili jsme si spoustu zábavy a&nbsp;moc se těšíme na&nbsp;další podobné události.</p>

				<h2 className="ftrs-h2 mt-4 mb-4">Fotogalerie ze Sportovního odpoledne na&nbsp;ČZU</h2>

				<Row className="mt-5 ftrs-blogpost-gallery">

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery1} alt="Sportovní odpoledne na ČZU" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery2} alt="Sportovní odpoledne na ČZU" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery3} alt="Sportovní odpoledne na ČZU" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery4} alt="Sportovní odpoledne na ČZU" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery5} alt="Sportovní odpoledne na ČZU" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery6} alt="Sportovní odpoledne na ČZU" />
					</Col>

				</Row>

				<h2 className="ftrs-h2 mt-4 mb-4">Future Rockstars na Stravě...</h2>

				<p>Future Rockstars má i&nbsp;svůj Strava klub, jak jinak než pod&nbsp;názvem Future&nbsp;Rockstars ;) Zatím má jen pár členů, ale&nbsp;už teď se v&nbsp;něm týdně naběhá stovky kilometrů. Pokud máte účet na&nbsp;Stravě a&nbsp;chcete se k&nbsp;nám přidat, <a className="ftrs-blacklink" href="https://www.strava.com/clubs/977570" target="_blank" rel="noreferrer">vítejte v&nbsp;klubu</a>!</p>

				<h3 className="ftrs-h3 mb-4 mt-4">Strava</h3>

				<Row>

					<Col xs="12" lg="8" className="mb-4" data-sal="fade" data-sal-duration="1000">
						<p>Pokud zatím nevíte, co je to Strava, pak vězte, že je to jedna z&nbsp;nejoblíbenějších mobilních aplikací na&nbsp;sledování kondice a&nbsp;sportovních aktivit. Zaznamenáváte trasy, mapujete svou oblíbenou cyklostezku nebo běžíte a&nbsp;analyzujete svůj trénink se&nbsp;všemi možnými statistikami. A&nbsp;co víc, jedná se o&nbsp;sociální síť sportovců, takže se můžete se&nbsp;svými sportovními přáteli propojit a&nbsp;sportovat společně.</p>
						<p>Aplikace Strava je ke stažení na&nbsp;<a className="ftrs-blacklink" href="https://play.google.com/store/apps/details?id=com.strava" target="_blank" rel="noreferrer">Google&nbsp;Play</a> nebo v&nbsp;<a className="ftrs-blacklink" href="https://apps.apple.com/cz/app/strava-run-ride-training/id426826309" target="_blank" rel="noreferrer">App&nbsp;Store</a>.</p>
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<iframe allowtransparency frameborder='0' height='160' scrolling='no' src='https://www.strava.com/clubs/977570/latest-rides/5221d3119e68e3d350547051887c9b55aa852518?show_rides=false' width='100%' title="Strava box"></iframe>
					</Col>

				</Row>

			</Container>
		</div>

		<Container className="pt-4 pb-4">
			<Bloglist></Bloglist>
		</Container>

	</Layoutcs>

)

export default Blog