import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Headercs from "../headercs/headercs"
import ScrollUpButton from "react-scroll-up-button"
import "../layout/layout.scss"
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const Layoutcs = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleCsQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  return (
    <>
      <Headercs siteTitleCs={data.site.siteMetadata.title} />

      {children}

      <ScrollUpButton
          ContainerClassName="ftrs-back2top"
          TransitionClassName="ftrs-back2top--visible"
          ShowAtPosition={200}
      >
        <ExpandLessIcon></ExpandLessIcon>
      </ScrollUpButton> 
    </>
  )
}

Layoutcs.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layoutcs
